.visitScoringPanelGroup {

  .panel-default {
    border-color: transparent;
  }

  .query-builder .rule-container {
    white-space: nowrap;
  }

  .panel-heading {

    @include layout-tablet-portrait {
      width:66%;
    }
    @include layout-desktop {
      width:55%;
    }
    @include layout-desktop {
      width:33%;
    }

    padding:0;

    > a {
      display: block;
      padding: 10px 15px;
    }
  }

  .query-builder .rules-list > *:before {
    display:none;
  }

  .query-builder .rules-group-container .rules-list {
    list-style: none;
    padding:0;
  }

}
