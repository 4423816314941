.btn {
  &.btn-primary {
    text-transform:uppercase;
    box-shadow:none;
    background:$tint-primary-orange linear-gradient(lighten($tint-primary-orange, 10%), $tint-primary-orange);
    color:white;
    border:1px solid $tint-primary-orange;
    &:hover {
      background:$tint-primary-orange linear-gradient($tint-primary-orange, lighten($tint-primary-orange, 10%));
    }
    &.active, &:active {
      background:darken($tint-primary-orange,5%) linear-gradient(darken($tint-primary-orange,5%), lighten($tint-primary-orange, 5%));
      &:hover {
        border:1px solid darken($tint-primary-orange, 10%);
      }
    }
  }
}

.btn {
  &.outlineBtn {
    border:1px solid $tint-primary-orange;
    background-color:transparent;
    color:white;
    &:hover, &.active, &:focus, &:active {
      background-color:rgba(white,.05);
      color:white;
      outline:none;
    }
  }
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
  background-color:$tint-lightest-gray;
}

.btn-link {
  color:$tint-primary-orange;
  &:hover, &:active, &:focus {
    color:darken($tint-primary-orange, 10%);
  }
}

.table-striped > tbody > tr:nth-of-type(even) {
  background-color: $tint-lighter-gray;
}

.pager {
  &:empty {
    display:none;
  }
}

.panel-default > .panel-heading {
  background-color:$tint-lighter-gray;
  border-color: $tint-darkest-border;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: $tint-darkest-border;
}


.datepicker {
  z-index:1040 !important;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
  color: #fff;
  background-color:$tint-primary-blue;
}
