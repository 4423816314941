%btnSecondary {
  box-shadow:none;
  background:$tint-primary-green linear-gradient(lighten($tint-primary-green, 10%), $tint-primary-green);
  color:white;
  border:1px solid $tint-primary-green;
  &:hover {
    background:$tint-primary-green linear-gradient($tint-primary-green, lighten($tint-primary-green, 10%));
  }
}

%btnTransp {
  border:none;
  background:transparent;
  &:active {

  }
  &:hover, &:active, &:focus {
    background-color:$tint-lightest-gray;
    box-shadow:none;
    outline:none;
  }
}

%btnTextPrimary {
  color:$tint-primary-blue;
  &:hover, &:active, &:focus {
    color:darken($tint-primary-blue, 10%);
  }
}

.btn {
  &.iconBtn {
    background-color:$tint-primary-blue;
    font-size: 16px;
    width: 40px;
    height: 40px;
    padding: 10px;
    border-radius: 20px;
    box-shadow:none;
    line-height: 1;
    color:white;

    &:hover {
      background-color:$tint-hover-blue;
    }

    &.active {
      background-color:$tint-primary-orange;
      color:white;
      box-shadow:none;
      pointer-events:none;
    }

    &.small {
      font-size: 14px;
      width: 24px;
      height: 24px;
      padding: 3px;
      border-radius: 12px;
    }

  }

  &.btnSecondary {
    @extend %btnSecondary;
  }

  &.btnTransp {
    @extend %btnTransp;
  }

  &.btnTextPrimary {
    @extend %btnTextPrimary;
  }
}

.okBtn {
  padding-left:30px;
  padding-right:30px;
}

.buyNowBtn {
  margin-bottom:-15px;
}

#socialLoginList {
  button {
    color:white;
    border:none;
  }
}

button#Facebook {
  background-color:$tint-fb;
  &:hover {
    background-color:darken($tint-fb, 10%);
  }
}

button#Twitter {
  background-color:$tint-twitter;
  &:hover {
    background-color:darken($tint-twitter, 10%);
  }
}

button#Google {
  background-color:$tint-gplus;
  &:hover {
    background-color:darken($tint-gplus, 10%);
  }
}

button#Microsoft {
  background-color:$tint-ms;
  &:hover {
    background-color:darken($tint-ms, 10%);
  }
}

.forgotPassword {
  a {
    font-size:12px;
    font-weight:normal;
  }
}

#btnLoadPanel {
  margin-left:50px;
}
