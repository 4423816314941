.creditsCounterWidgets {
  text-align: center;
  background-color:$tint-lightest-blue;
  border-radius:10px;
}

.automationsCarousel {
  background-color:$tint-lighter-gray;
  border-radius:10px;
  margin: 20px 0;
  min-height: 224px;

  .carousel-inner, .item {
    min-height: 224px;
  }

  .item {

    h4 {
      margin:0;
      padding:15px 0;
    }

    p {
      min-height: 60px;
    }

    .automationControls {
      text-align:center;
      padding-bottom:10px;
      border-top:1px solid $tint-darker-border;
    }
  }

  .carousel-control {
    background-image:none;
    color:$tint-primary-blue;
    text-shadow:none;
    width: 10%;
  }

  .item {
    padding: 10px 10%;
  }

  .flexWrapper {
    display:flex;
    justify-content:space-between;
  }

}

.setupAutomationBtn {
  width:180px;
}

.dashboardTabs {
  padding-top:10px;
  .nav-tabs {
    border-bottom:none;
  }
  .nav-tabs > li {
    float: right;
    a {
      font-size:14px;
      font-weight:normal;
    }
  }
  .tabs > li > a {
    border:none;
  }
  .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    color: $tint-primary-orange;
    border:none;
    border-bottom-color: transparent;
    font-weight:bold;
  }
  .tab-content > .tab-pane {
    height: 211px;
  }
  .carousel-inner {
    padding-top: 10px;
  }
}

.locationsHeader {
  .customSelectGroup {
    width:50%;
  }
}

.mapLegend {
  > div > * {
    margin-right:5px;
    display:inline-block;
    vertical-align:middle;
    margin-bottom:0;
    font-size:11px;
    .leftGroup {
      text-align:left;
    }
    .rightGroup {
      text-align:right;
    }
  }
}

.colorTile {
  display:inline-block;
  width:20px;
  height:20px;
}

.dashboardTable {
  a {
    @extend %lighterGraylinkStyle;
  }
}

.chartContainer {
  height:300px;
}