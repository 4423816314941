div.customSelectGroup {
  position:relative;
  margin-left:-5px;
  margin-bottom:0;
  select {
    appearance: none;
    border:none;
    border:1px solid transparent;
    padding-right:30px;
    height:30px;
    background-color:$tint-lightest-gray;
    &:hover {
      border:1px solid $tint-gray-border;
      background-color:lighten($tint-lightest-gray, 5%);
    }
    &:active, &:focus {
      background-color:darken($tint-lightest-gray, 5%);
      box-shadow:none;
      border:1px solid $tint-gray-border;
      outline:none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  select::-ms-expand {
    display: none;
  }
  .form-control {
    padding: 3px 24px 3px 12px;
    box-shadow:none;
  }
  &:after {
    @extend %font-awesome;
    position: absolute;
    top: 8px;
    right: 9px;
    content: "\f078";
    pointer-events: none;
  }
}
