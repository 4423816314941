.icon-bigx {
  font-size:30px;
}

.iconColumn {
  width: 60px;
  text-align: center;
}

.icon-btn {
  .btn-glyphicon {
    &:hover {
      background:$tint-lightest-gray;
    }
  }
}
