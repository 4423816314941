@font-face {
  font-family: 'icomoon';
  src:    url('fonts/icomoon.eot?8ohkzk');
  src:    url('fonts/icomoon.eot?8ohkzk#iefix') format('embedded-opentype'),
  url('fonts/icomoon.ttf?8ohkzk') format('truetype'),
  url('fonts/icomoon.woff?8ohkzk') format('woff'),
  url('fonts/icomoon.svg?8ohkzk#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"], %iconFont {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-collapse-all:before {
  content: "\e903";
}
.icon-expand-all:before {
  content: "\e904";
}
.icon-bigx:before {
  content: "\e902";
}
.icon-pointyChevron:before {
  content: "\e901";
}
.icon-pointyMediumChevron:before {
  content: "\e900";
}
