html {
  position:relative;
  &.loading {
    width:100vw;
    height:100vh;
    overflow:hidden;
    .mainNavigation, .bodyContent {
      height:100vh;
      overflow:hidden;

      //display:none;

    }
    .spinnerOverlay {
      display:block;
    }
  }
}

.spinnerOverlay {
  //background:rgba(black,0.5);
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:1031;
  display:none;
}
