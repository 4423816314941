.companyPopover + .popover {
  max-width: 440px;
}

.popover {
  font-family:$font-open-sans;
  h2 {
    margin:0;
    padding:5px 0;
  }
  .flexWrapper {
    display:flex;
    background-color:$tint-lightest-gray;
    justify-content: space-between;
    align-items: center;
    > div {
      padding:10px;
    }
  }
  .indicatorTile {
    text-align:center;
    .fa {
      font-size:36px;
      color:$tint-primary-orange;
    }
    .hugeNo {
      color:$tint-primary-blue;
      display:block;
      font-size:20px;
    }
    label {
      font-size:12px;
      color:$tint-secondary-gray;
    }
  }
  .companyWebsite {
    padding:5px 0;
    span, a {
      display:inline-block;
      font-size:12px;
    }
  }
}

.companyDetailsList {
  align-self: flex-start;
  ul.industryLists {
    @extend %resetList;
  }
  li {
    @extend %resetList;
    padding-left:10px;
  }
}