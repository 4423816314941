.bodyContent {
  .query-builder .rules-group-container {
    border-color: white;
    background-color:darken($tint-lighter-gray, 5%);
  }
}

.query-builder .rule-container .rule-actions {
  button {
    margin:6px 0;
  }
}


.btn-group.group-conditions {

  > .btn.active:first-child:not(:last-child):not(.dropdown-toggle), > .btn.active:last-child:not(:first-child):not(.dropdown-toggle) {
    position:relative;
    border-right-width:2px;
    color:darken(white, 10%);
    &:after {
      content:'';
      position:absolute;
      height:100%;
      width:10px;
      top:0;
    }
  }

  > .btn.active:first-child:not(:last-child):not(.dropdown-toggle) {
    &:after {
      background:transparent linear-gradient(to right, rgba(black,0), rgba(black,0.3));
      right:-1px;
    }
  }
  > .btn.active:last-child:not(:first-child):not(.dropdown-toggle) {
    &:after {
      left:-1px;
      background:transparent linear-gradient(to right, rgba(black,0.3), rgba(black,0));
    }
  }
}

.btn-group.group-actions {
  > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-right-width:2px;
  }
  .btn.btn-success {
    text-transform: capitalize;
    @extend %btnSecondary;
  }
}

.btn-group.rule-actions {

  > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-right-width:2px;
  }

  .btn.btn-danger {
    .glyphicon.glyphicon-remove {
      &:before {
        content: "\e020";
      }
    }
  }
}

.query-builder .rules-list > *:before, .query-builder .rules-list > *:after {
  border-color:white !important;
}
