/* Set padding to keep content from hitting the edges */
.body-content {
  //padding-left: 15px;
  //padding-right: 15px;
}

/* Override the default bootstrap behavior where horizontal description lists
   will truncate terms that are too long to fit in the left column
*/
.dl-horizontal dt {
  white-space: normal; }

/* Set width on the form input elements since they're 100% wide by default */
input,
select,
textarea {
 // max-width: 280px;
}

.nav {
  font-size: 1.15em; }

.table-condensed {
  width: 100%;
  border-collapse: collapse;
  padding: 1px 2px 3px 2px; }

div.form-group {
  margin-bottom: 0.5em; }

.page-caption {
  font-size: 22px; }

#tabs {
  font-size: 1em;
  font-weight: bold;
  white-space: nowrap; }

/* Styles for buttons
----------------------------------------------------------*/
.btn-glyphicon {
  padding: 8px;
  background: #ffffff; }

.icon-btn {
  padding: 1px 2px 3px 2px;
  border-radius: 50px; }

.filter-btn {
  padding: 3px 36px 3px 36px; }

.grid-header {
  height: 36px; }

.btn-page-caption {
  margin-top: 12px;
  margin-bottom: 6px; }

.grid-caption {
  color: cornflowerblue;
  font-weight: bold;
  line-height: 32px;
  display: inline-block;
  vertical-align: middle; }

/* Styles for pager
----------------------------------------------------------*/

/*

.pager {
  padding-top: .4em;
  padding-bottom: .4em; }

.pager a {
  border: 1px solid lightgray;
  margin-right: 0.4em;
  width: 1em;
  padding: 0.2em 0.5em 0.2em 0.5em;
  text-decoration: none;
  color: white; }

.pager a #hover {
  font-weight: bold; }

.pager-btn {
  border-radius: 50px; }

.current {
  background-color: lightblue;
  font-weight: bold; }

*/