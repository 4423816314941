.toolBar {
  display:flex;
  flex-wrap: wrap;
  > button {
    margin:5px;
  }
  > * {
    margin-top:0;
    margin-bottom:0;
  }
  &.big {
    padding:20px 0;
  }
  &.medium {
    padding:10px 0;
  }
  &.center {
    justify-content: center;
  }
  &.distributed {
    justify-content: space-between;
  }
  &.stacked {
    &.left {
      justify-content: flex-start;
      > * {
        margin-right:10px;
      }
    }
    &.right {
      justify-content: flex-end;
      > * {
        margin-left:10px;
      }
    }
  }
  &.top {
    align-items:flex-start;
  }
  &.middle {
    align-items: center;
  }
  &.bottom {
    align-items:flex-end;
  }
}

.mainToolBar {
  margin-top:10px;
  border-bottom:1px solid $tint-darker-border;
  align-items:center;
  .hugeBtn {
    height: 50px;
    padding: 10px 20px;
    font-size:20px;
    margin:0;
  }
  .resultsNo {
    label {
      font-weight:normal;
      font-size:20px;
    }
  }
  label {
    display:block;
  }
  .rightGroup {
    label {
      text-align:right;
      padding: 5px 0;
    }
  }
}

.inputGroupFlexWrapper {
  width:300px;
}

.flexInputGroup {
  width: 450px;
  display:flex;
  align-items:center;
  justify-content: space-between;
  label {
    white-space:nowrap;
  }

  > * {
    margin:0 5px;
  }
}

.optionsPanel {
  background-color:$tint-lightest-gray;
  padding-top:20px;
  padding-bottom:20px;
  border-radius: 5px;

  h3 {
    font-size:20px;
    border-bottom:1px solid $tint-darker-border;
  }

  .flexContainer {
    display:flex;
    align-items:center;
    flex-wrap: wrap;
    justify-content: center;

    @include layout-tablet-landscape {
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    .packageTile {
      padding:50px 10px 10px;
      margin:10px;
      height:230px;
      background:white;
      border:1px solid $tint-darkest-border;
      display:flex;
      flex-direction: column;
      justify-content: space-between;
      width: 200px;
      text-align:center;
      @include layout-tablet-landscape {
        width: 20%;
      }

      h4 {
        font-size:16px;
        text-transform:uppercase;
      }

      .totalPrice {
        font-size:30px;
        font-weight:bold;
      }
      .pricePerCredit {
        font-size:12px;
      }

      header {
        position:absolute;
        width:100%;
        top:0;
        left:0;
        height:30px;
        line-height:2;
        &.mostPopular {
          background-color:$tint-light-blue;

        }
        &.bestValue {
          background-color:$tint-primary-blue;
          color:white;
        }
      }

      &.withHeader {
        position:relative;
      }

      .buyNowBtn {
        visibility:hidden;
        pointer-events:none;
      }

      &.active {
        border:1px solid $tint-primary-blue;
        box-shadow:1px 1px 3px rgba($tint-primary-blue, 0.3);
        .buyNowBtn {
          visibility:visible;
          pointer-events:auto;
        }
      }
    }
  }
}

.row.mainHeading {
  padding:20px 0;
  border-bottom:1px solid $tint-darker-border;
  margin-bottom:15px;
  h1 {
    margin-top:3px;
    margin-bottom:3px;
  }
}

.row.mainFooter {
  margin-top:15px;
  padding:20px 0;
  text-align:left;
  border-top:1px solid $tint-darker-border;

  .btn-link.btn-lg {
    padding-left:0;
    padding-right:0;
    margin-right:10px;
  }

  .btn-primary.btn-lg, .btn-danger.btn-lg {
    margin-left:10px;
  }
}

.mainNavigation {
  background-color:white;
}

.dashboardTable {
  > thead > tr >th {
    font-size:12px;
  }
}

.dateOffset {
  font-size:12px;
  span {
    display:block;
  }
  .dateVar {
    font-weight:bold;
  }
}

.btn.btnTransp {
  .glyphicon.glyphicon-edit {
    color:$tint-primary-blue;
  }
  &:hover, &:active, &:focus {
    color:darken($tint-primary-blue, 10%);
  }
}

.footnote {
  font-size:11px;
  display:inline-block;
  padding:5px;
  color:$tint-default-gray;
}

.checkboxGrid {
  flex-wrap: wrap;
  .checkbox {
    float:left;
    width:50%;
  }
}
