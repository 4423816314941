.pager {
  display:block;
  text-align:center;
  margin:10px 0;
  .pager-btn {
    display:inline-block;
    padding:4px 12px;
    border:1px solid $tint-gray-border;
    border-radius:3px;
    margin:0 3px;
    color:$tint-primary-orange;
    &:hover {
      background-color: $tint-lightest-gray;
      text-decoration:none;
      color:darken($tint-primary-orange, 5%);
    }
    &.current {
      background-color:$tint-primary-orange;
      color:white;
    }
  }
}