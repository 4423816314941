.k-popup {
  background-color:white;
  width:200px;
  padding:10px;
}

.popupTooltip.k-popup {
  border:1px solid $tint-primary-blue;
}

#map {
  background-color:#237092;
  border-radius:10px;
}