.cloudTagsContainer {
  padding:10px;
  border-radius:10px;
  background:$tint-primary-blue linear-gradient($tint-primary-blue, lighten($tint-primary-blue, 30%));
  text-align: center;
  min-height:200px;
  position:relative;
  .cloudTags {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    max-height: 100%;
    width: 100%;
    padding: 20px;
    overflow:hidden;
    a {
      color:white;
      line-height: 0.8;
      margin:3px 8px;
      display:inline-block;
      pointer-events: none;
      &:hover {
        color:white;
      }
      &:nth-child(2n +2){
        vertical-align: super;
      }
    }
  }
}