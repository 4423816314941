.cbGrid {
  margin:20px 0;

  .checkColumn {
    text-align: center;
    width:40px;
  }

  .gridActionsToolbar {
    width: 96px;
  }

  &.table-striped {
    > tbody > tr {
      background-color:$tint-lighter-gray;
      &:hover {
        background-color:$tint-lightest-blue;
      }
    }
    > tbody > tr:nth-of-type(even) {
      background-color:white;
      &:hover {
        background-color:$tint-lightest-blue;
      }
    }
  }

  > thead > tr > th {
    border-bottom:1px solid $tint-darker-border;
  }

  > thead > tr > th > a.gridHeader {
    display:block;
    position:relative;
  }

  > tbody > tr > td {
    height:60px;
  }

  > tbody > tr {
    position:relative;

    &:hover {
      background-color:$tint-lightest-blue;
    }

  }

  &.collapseGrid {

    > tbody > tr {
      border-bottom:3px solid transparent;

      &:nth-child(4n-7){
        background-color:$tint-lighter-gray;
        &:hover {
          background-color:$tint-lightest-blue;
        }
      }

      &.headerRow {
        cursor:pointer;
        display: table-row;
        &:hover {
          border-bottom:3px solid $tint-primary-blue;
        }
      }

      > td.mainToggleCell .toggleIcon {
        display:none;
        position:absolute;
        left:50%;
        transform:translate(-50%, 16px);
        font-size:14px;
        width:24px;
        height:24px;
        background-color:$tint-primary-blue;
        color:white;
        border-radius:12px;
        line-height:1.6;
        text-align:center;
        z-index: 200;
        &:before {
          content: "\f077";
        }
      }

      &:hover > td.mainToggleCell .toggleIcon {
        display:inline-block;
      }

      &.detailsRow {
        background-color:$tint-darker-border;

        > td {
          padding: 0 1px 1px;
        }
        // display:none;


        &.expandedRow, &.expandedSingleRow {
          display:table-row;
        }
      }

      .detailsContainer {
        .table-responsive {
          background-color:white;
        }
      }
    }

    > tbody > tr.headerRow.collapsed > td.mainToggleCell .toggleIcon {
      &:before {
        content: "\f078";
      }
    }

  }

}

.cbDetailsTable {

  > thead > tr > th {
    height: 53px;
  }

  tbody > tr {
    //display:none;
    &:hover {
      background-color:$tint-lightest-blue;
    }
  }

  .collapseColumn {
    width:30px;
  }

  tbody > tr.headerRow {
    cursor:pointer;
    display:table-row;
  }
}

.rowLevel1 {
  cursor:pointer;
}

.detailsRow {

  .detailsContainer.leadTab {
    background-color:white;
    padding-bottom:10px;

    .companyDetailsList.indicatorTile {
      align-self: center;
      .hugeNo {
      font-size: 18px;
      }
    }

    .companyDetailsList ul.industryLists {
      min-height: 85px;
    }

    .fa.fa-usd {
      font-size: 30px;
      background-color:$tint-primary-orange;
      width: 36px;
      height: 36px;
      color: white;
      text-align: center;
      border-radius: 18px;
      line-height: 1.2;
    }

    header {
      padding:15px;
      .text-right label {
        margin-bottom: 10px;
      }
    }

    h2 {
      margin:0;
      padding:10px 20px;
    }

    .col-sm-8 {
      display:flex;
      align-items: stretch;
    }

    .flexWrapper {
      display:flex;
      width:100%;
      background-color:white;
      justify-content: space-around;
      align-items: center;
    }

    .visitDetailsData {
      background-color:$tint-lightest-gray;
      border-radius:5px;
    }

    .row {
      @include layout-tablet-portrait {
        display: flex;
        align-items: stretch;
      }
    }

    .col-sm-6 {
      &:nth-child(2) {
        margin-top: 20px;
        @include layout-tablet-portrait {
          margin-top:0;
        }
      }
      @include layout-tablet-portrait {
        display: flex;
        align-items: stretch;
      }
      > div {
        width:100%;
      }
    }

  }

  .visitDetailsData {
    padding:10px;

    .flexRow {
      display:flex;
      align-items:center;
      justify-content: space-between;
      padding:10px 0;
      > *:first-child {
        margin-right:5px;
      }
    }

    .nonFlexRow {
      padding:5px 0;
      > * {
        display:inline-block;
        vertical-align: top;
      }
      > *:first-child {
        margin-right:5px;
      }
    }
  }

  .indicatorTile {
    min-width: 140px;
    text-align:center;
    .fa {
      font-size:36px;
      color:$tint-primary-orange;
    }
    .hugeNo {
      color:$tint-primary-blue;
      display:block;
      font-size:32px;
      font-weight:700;
    }
    label {
      font-size:14px;
      color:$tint-secondary-gray;
    }
  }
}


.cbDetailsTableContainer {
  //max-height:350px;
  //overflow-y:auto;
}

//data styles
.locationLbl {
  display:block;
}

.scoreLbl {
  font-size:24px;
  font-weight:700;
}

.companyRect {
  @extend %threeDots;
  min-height: 46px;
  max-width: 100%;
  text-decoration: none;
  display: block;
  padding: 13px 5px;
  color:$tint-primary-blue;
  font-weight:normal;
  &:hover,&:active,&:focus  {
    color:darken($tint-primary-blue, 5%);
    text-decoration:none;
  }
}

.companyShort {
  @extend %threeDots;
  max-width: 140px;
  display: block;
}
