.detailsTabs {
  padding: 0 1px;

  .nav-tabs {
    border-bottom:none;
  }

  .nav-tabs > li {
    > a {
      background-color:$tint-lighter-gray;
      color:$tint-primary-blue;
      min-width:200px;
      border:none !important;
    }
    &.active > a {
      background-color:white;
    }
  }

  .detailsTabs__content {
    padding:10px 0;
    background-color:white;

    header {
      border-bottom:1px solid $tint-darker-border;
      margin-bottom:20px;
      padding:14px 0;
    }

    .flexWrapper {
      display:flex;
      align-items: center;
      min-height:168px;
    }

    .visitDetailsData {
      flex-grow: 2;
      .flexRow {
        display:flex;
        align-items:center;
        justify-content: left;
        padding:10px 0;
        > *:first-child {
          margin-right:20px;
        }
        > * {
          width:50%;
          word-break: break-word;
        }
      }
    }

    .indicatorTile {
      min-width: 140px;
      text-align:center;
      .fa {
        font-size:36px;
        color:$tint-primary-orange;
      }
      .hugeNo {
        color:$tint-primary-blue;
        display:block;
        font-size:32px;
        font-weight:700;
      }
      label {
        font-size:14px;
        color:$tint-secondary-gray;
      }
    }

    &.visit .flexWrapper {
      justify-content: space-between;
    }

    &.company .flexWrapper {
      justify-content: space-around;
    }

    .nonBoldURL {
      font-weight:normal;
    }

  }
}
