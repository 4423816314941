.mainNavigation {

  .creditsCounter {
    text-align: center;
    padding: 3px 10px;
    font-size:12px;

    .counterBtn {
      padding:3px 3px 0 3px;
      @include layout-desktop {
        display:block;
      }
      &:hover {
        background-color:$tint-lighter-gray;
      }
    }

    @include layout-desktop {
      padding: 0 10px 5px 10px;
      margin-top:-15px;
    }

    .counterWrapper {
      vertical-align: middle;
      display:inline-block;

      //zoom:0.6;
      //-moz-transform: scale(0.6);

      @include layout-desktop {
        display:block;
        text-align: center;
      }
    }

    label {
      text-align: center;
      @include layout-tablet-portrait {
        text-align: left;
      }
      color:$tint-secondary-gray;
      font-size:11px;
      font-weight:normal;
      margin-bottom:0;
      display:block;
      @include layout-tablet-landscape {
        text-align: center;
      }
    }

    .btn.btn-sm {
      line-height:1.1;
    }

    button {
      font-size:11px;
      margin-top:1px;
    }
  }

  &.navbar-default {
    background-color:white;
    //border-color:$tint-darker-border;
    //box-shadow: 0 1px 2px rgba(black,0.1);
    border-color:$tint-gray-border;
  }

  .nav {
    font-size:inherit;
  }

  .navbar-brand {
    padding: 3px 15px;
    height:auto;
    @include layout-tablet-landscape {
      padding: 41px 10px 10px;
    }

  }

  .clickbackLogo {
    width: 115px;
    height: auto;
    margin-top: 9px;
    @include layout-tablet-landscape {
      margin-top:0;
      width:300px;
      height:auto;
    }
  }

  .navbar-nav {
    position:relative;
    li:not(:last-child) {
      margin-right:1px;
    }
    li:not(:last-child):after {
      content:'';
      position:absolute;
      height:80%;
      top:10%;
      right:-1px;
      display:inline-block;
      width:1px;
      background-color:$tint-gray-border;
    }

    &.pagesNav {
      position:relative;
      @include layout-tablet-landscape {
        clear:right;
        margin-top: 15px;
      }

      &:not(:last-child) {
        margin-right:1px;
      }
      &:not(:last-child):after {
        content:'';
        position:absolute;
        height:80%;
        top:10%;
        right:-1px;
        display:inline-block;
        width:1px;
        background-color:$tint-gray-border;
      }

      > li {
        &.active, &:active, &:focus {
          .fa, .glyphicon {
            color:$tint-primary-orange;
          }
          > a {
            background-color:$tint-primary-blue;
            color:white;
          }
          &:hover {
            > a {
              background-color:$tint-hover-blue;
            }
          }
        }
        &:hover {
          > a {
            background-color:$tint-lightest-gray;
          }
        }
      }

      @include layout-tablet-landscape {
        > li {
          > a {
            padding-top: 20px;
            padding-bottom: 20px;
            border-radius:5px 5px 0 0;
          }
        }
      }

      .mainNavigation__label {
        @include layout-tablet-landscape {
          display:inline-block;
        }
      }

    }

  }

  &.navbar-default .userMenu {
     .navbar-nav > li > a {
       font-weight:normal;
    }
  }

  .userMenu {
    font-size:12px;
    .fa, .glyphicon {
      color:$tint-secondary-gray;
      &:hover {
        color:darken($tint-secondary-gray, 10%);
      }
    }
    .navbar-nav {
      &:not(:first-child) {
        margin-right: 1px;
      }
      &:not(:first-child):after {
        content:'';
        position:absolute;
        height: 16px;
        top: 18px;
        right:-1px;
        display:inline-block;
        width:1px;
        background-color:$tint-gray-border;
      }
    }
    .navbar-nav > li > a {
      padding-top: 15px;
      padding-bottom: 15px;
      @include layout-tablet-landscape {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
    @include layout-tablet-landscape {
      .navbar-nav > li > a {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }

  .fa, .glyphicon {
    color:$tint-primary-orange;
    font-size:16px;
    vertical-align: middle;
    display:inline-block;
    width:16px;
    height:16px;
  }

  .fa.fa-chevron-down  {
    color:$tint-secondary-gray;
    font-size:12px;
    width:12px;
    height:12px;
  }

}

.mainNavigation__label {
  display:inline-block;
  @include layout-tablet-portrait {
    display:none;
  }
  @include layout-desktop {
    display:inline-block;
  }
}