body {
  font-family:$font-open-sans;
  color:$tint-default-gray;
  padding-top: 50px;
  padding-bottom: 50px;

  @include layout-desktop {
    padding-top: 115px;
  }

}

h1,h2,h3,h4 {
  font-family: $font-hind;
  color:$tint-primary-green;
}

h1, h2 {
  font-weight:700;
}

h3,h4 {
  color:$tint-primary-blue;
}

h1 {
  font-size:26px;
}

h2 {
  font-size:20px;
}

a {
  @extend %greenLinkStyle;
  font-weight:bold;
}

section {
  margin-bottom:20px;
  &:last-child {
    margin-bottom:50px;
  }
}

hr {
  margin-top: 5px;
  margin-bottom: 5px;
  border-top: 1px solid $tint-darkest-border;
}

//modal styling

.modal-header {
  border-bottom:none;
}

.modal-title {
  font-size:24px;
  font-weight:bold;
}

.modal-body {
  padding:0 15px;
}

.modal-footer {
  border-top:none;
}