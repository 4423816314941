.dashboardBanner {
  text-align: center;
  background:$tint-primary-blue linear-gradient(lighten($tint-primary-blue, 10%), lighten($tint-primary-blue, 20%));
  margin-bottom:20px;
  font-size:10px;
  color:white;
  overflow:hidden;
  .flexWrapper {
    display:flex;
    align-items: center;
    justify-content: space-between;

  }

  .pointySeparator {
    position:relative;
    align-self: stretch;
    &:after {
      @extend %iconFont;
      content: "\e900";
      position: absolute;

      top: -5px;
      font-size:12em;
      @include layout-tablet-portrait {
        font-size:17em;
      }
    }

    &:after {
      right: -45px;
      color: rgba(white,.1);
      @include layout-tablet-portrait {
        right: -45px;
      }
    }
  }

  button.btn.outlineBtn {
    margin: 1em;
    padding: 1em;
    font-size: 1.3em;
    border-width: 3px;
    .fa {
      color:white;
      line-height:1;
    }
    .hugeNo {
      line-height:1;
      font-size: 3em;
    }
    label {
      color:white;
      line-height:1;
    }
  }

  .fa {
    color:$tint-primary-orange;
    font-size:1.6em;
    @include layout-tablet-portrait {
      font-size:2.4em;
    }
  }

  .indicatorTile {
    padding: 1.5em;
    @include layout-desktop {
      &:not(.hover){
        min-width: 200px;
      }
    }

    &.hover {
      cursor:pointer;
      padding: 1em 2em;
      margin: 1em;
      background-color:rgba(white,0.1);
      &:hover {
        background-color:rgba(white,0.2);
      }
    }

    label {
      color:$tint-lighter-gray;
      margin:0;
      font-size:1em;
      @include layout-tablet-portrait {
        font-size:1.4em;
      }
    }

    .hugeNo {
      display:block;
      font-weight:800;
      text-align:center;
      line-height:1.5;
      font-size:2.5em;
      @include layout-tablet-portrait {
        font-size:5em;
      }
    }

  }

}