$tint-primary-blue:                       #0d2a37;
$tint-light-blue:                         #8fc0da;
$tint-lightest-blue:                      lighten($tint-light-blue, 20%);
$tint-primary-green:                      #368640;
$tint-primary-orange:                     #ee4e10;


$tint-default-gray:                       #333333;
$tint-secondary-gray:                     #999999;
$tint-lighter-gray:                       #E4E4E4;
$tint-lightest-gray:                      #F2F2F2;

$tint-hover-blue:                         rgba($tint-primary-blue, .8);
$tint-hover-green:                        rgba($tint-primary-green, .8);
$tint-hover-orange:                       rgba($tint-primary-orange, .8);

$tint-gray-border:                        #e7e7e7;
$tint-darker-border:                      #D0D0D0;
$tint-darkest-border:                     #C7C7C7;

$mainGradient:                            $tint-primary-blue linear-gradient(lighten($tint-primary-blue, 10%), lighten($tint-primary-blue, 20%));

//top 3
$tint-top-1:                              #ee4e10;
$tint-top-2:                              lighten($tint-top-1, 2%);
$tint-top-3:                              lighten($tint-top-1, 4%);

$tint-fb:                                 #4D70A8;
$tint-gplus:                              #E3401D;
$tint-twitter:                            #00BBF2;
$tint-ms:                                 #05A6F0;