.cbRadioList {
  .subOptions {
    height:0;
    overflow:hidden;
    visibility:hidden;
  }

  .openGroup .subOptions {
    height:auto;
    visibility:visible;
    padding:10px 20px 10px 10px;
  }

  .radio {
    label {
      display:flex;
      align-items: center;
      .btn {
        padding:6px;
      }
      input[type="radio"],
      input[type="checkbox"] {
        margin-top:0;
        position: relative;
        margin-right: 6px;
      }
    }
  }

}