.purchaseSummary {
  .fa.fa-arrow-right {
    color:white;
  }
  .panel-body {
    background-color: #e6f0d7;
  }
}

.text-box {
  @extend %form-control;
}

.accountInfoWrapper {
  .row {
    padding:5px 0;
  }
}

@media (min-width: 768px) {
  .expMonth {
    width: 21.66666667%;
  }
  .expYear {
    width: 20%;
  }
}

