.filterPanel {
  min-height:50px;
  padding-left:10px;
  padding-right:10px;
  background-color:$tint-lighter-gray;
  border-radius: 5px;
  border:1px solid $tint-darkest-border;
  h2 {
    margin: 0 10px 0 0;
    line-height: 1.8;
  }
}

.filterActions {
  .btn-link {
    text-transform: uppercase;
  }
}

.faChevron {
  &:before {
    content: "\f077";
  }
}

.builderWrapper {
  margin-bottom:20px;
}

.collapsed .faChevron {
  &:before {
    content: "\f078";
  }
}
